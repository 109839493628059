import $ from 'jquery'
import { fillTrafficInfo } from './sourcebuster'
import { triggerAnalyticsEvent } from './analytics'
import { getQueryStringParam } from './queryStringParser'

fillTrafficInfo()

const contactFormButton = $('[data-id="contact-form-button"]')
const errorsList = $('[data-id="form-errors"]')
const formFields = $('[data-field]')
const formInputs = $('[data-form]')
const contactForm = $('[data-id="contact-form"]')
const formEmailInput = $('[data-email-field]')
const contactError = $('[data-id="contact-error-info"]')

const withModifier = (modifier) => `contact__submit-button--${modifier}`

const addButtonModifier = (modifier) =>
  contactFormButton.addClass(withModifier(modifier))

const removeButtonModifier = (modifier) =>
  contactFormButton.removeClass(withModifier(modifier))

export const cleanErrors = () => {
  errorsList.html('')
  formFields.removeClass('contact--error')
}

const cleanForm = () => {
  const inputs = contactForm.find('input')
  const textAreas = contactForm.find('textarea')
  const selects = contactForm.find('select')

  const files = inputs.filter((index, input) => input.type === 'file')

  files.each(function () {
    const previousData = $(this).data('previous')
    const previousDataIsDefined = Boolean(previousData)
    if (previousDataIsDefined) {
      $(this).attr('data-value', previousData)
    }
  })
  inputs.val('')
  textAreas.val('')
  selects.each(function () {
    this.selectedIndex = 0
  })
}

export const addErrorList = (errors) => {
  const errorsString = errors.map(errorTemplate)

  errorsList.html(errorsString)
}

const setInvalidFields = (fields) => {
  fields.forEach((field) => {
    $(`[data-field="${field}"]`).addClass('contact--error')
  })
}

const showErrors = (errors, invalidFields) => {
  addErrorList(errors)
  setInvalidFields(invalidFields)
}

const errorTemplate = (error) => `<li class="error-message">${error}</li>`

const handleSubmit = () => {
  cleanErrors()
  addButtonModifier('loading')

  const email = formEmailInput.val() 
  const liFatId = getQueryStringParam("li_fat_id") 

  const userData = {}
  if (email && email.trim() !== '') { 
    userData.email_address = email;
  }

  if (liFatId && liFatId.trim() !== '') { 
    userData.linkedinFirstPartyId = liFatId;
  }

  triggerAnalyticsEvent(
    contactForm.attr("data-analytics-event"),
    Object.keys(userData).length !== 0 ? { user_data: userData } : undefined
  );
}

const disableInputs = () => formInputs.prop('disabled', true)

export const handleServerResponse = (event) => {
  const { responseJSON: response } = event

  removeButtonModifier('loading')

  if (event.status === 500 || !response) {
    const error = contactError.data('error')
    const errors = [error]

    return handleErrorsResponse(errors, [])
  }

  if (response.ok) {
    return handleSuccessResponse()
  }

  return handleErrorsResponse(response.errors, response.invalid_fields)
}

const handleSuccessResponse = () => {
  addButtonModifier('success')
  cleanForm()
  disableInputs()
}

const handleErrorsResponse = (errors, invalidFields) => {
  showErrors(errors, invalidFields)
  addButtonModifier('error')
  setTimeout(() => removeButtonModifier('error'), 1500)
}

const handleFormInteraction = () => {
  $('.grecaptcha-badge').css('visibility', 'visible')
}

$('form input, form textarea, form select').on('focus', handleFormInteraction)

const getRecaptchaFunctionsForAction = (action) => {
  switch (action) {
    case 'contact':
      return {
        executeRecaptcha: executeRecaptchaForContactAsync,
        setInputWithRecaptchaResponseToken: setInputWithRecaptchaResponseTokenForContact,
      }
    case 'job-apply':
      return {
        executeRecaptcha: executeRecaptchaForJobApplyAsync,
        setInputWithRecaptchaResponseToken: setInputWithRecaptchaResponseTokenForJobApply,
      }
  }
}

const revalidateRecaptchaToken = async (action) => {
  const {
    executeRecaptcha,
    setInputWithRecaptchaResponseToken,
  } = getRecaptchaFunctionsForAction(action)
  const token = await executeRecaptcha()
  setInputWithRecaptchaResponseToken(
    `g-recaptcha-response-data-${action}`,
    token,
  )
}

contactForm.on('submit', async function (e) {
  e.preventDefault()

  const form = $(this)[0]
  const url = form.getAttribute('action')
  const action = form.getAttribute('data-action')
  await revalidateRecaptchaToken(action)
  const formData = new FormData(form)

  $.ajax({
    url,
    type: 'POST',
    data: formData,
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    processData: false,
    contentType: false,
    beforeSend: handleSubmit,
    complete: handleServerResponse,
  })
})
